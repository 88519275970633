import React from 'react';
import { motion } from 'framer-motion';

const UnderDevelopment = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen  bg-gradient-to-r from-purple-600 to-indigo-600">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <motion.h1 
          className="text-4xl font-bold text-white mb-4"
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          Under Development
        </motion.h1>
        <motion.p 
          className="text-xl text-white"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          We're working hard to bring you something amazing!
        </motion.p>
      </motion.div>
      <motion.div
        className="mt-8"
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
      >
        <svg className="w-16 h-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      </motion.div>
    </div>
  );
};

export default UnderDevelopment;