import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginPrompt from '../LoginPrompt';
import { getBusinessLeads, getBusinessTypes, getUserCredit } from '../../utils/api';
import CreditPopup from '../CreditPopup';

function BusinessLeadsForm({ place, radius, onCreditUpdate }) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    businessType: '',
    withoutWebsite: false,
    operational: false,
    hasPhone: false,
    hasRecentReviews: false,
    hasReviews: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [showCreditPopup, setShowCreditPopup] = useState(false);
  const [userCredit, setUserCredit] = useState(null);
  const [customBusinessType, setCustomBusinessType] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const customInputRef = useRef(null);

  useEffect(() => {
    const fetchBusinessTypes = async () => {
      try {
        const response = await getBusinessTypes();
        // Ensure that response is an array before setting it to state
        if (Array.isArray(response)) {
          setBusinessTypes(response);
        } else {
          console.error('Business types response is not an array:', response);
          setBusinessTypes([]); // Set to empty array as fallback
        }
      } catch (error) {
        console.error('Error fetching business types:', error);
        setBusinessTypes([]); // Set to empty array on error
      }
    };
    fetchBusinessTypes();
    if (auth) {
      fetchUserCredit();
    }
  }, [auth]);

  const fetchUserCredit = async () => {
    try {
      const creditData = await getUserCredit(auth.token);
      const newCredit = creditData?.balance;
      setUserCredit(newCredit);
      return newCredit;
    } catch (error) {
      console.error('Failed to fetch user credit:', error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'businessType') {
      if (value === 'other') {
        setIsCustom(true);
        setFormData(prev => ({ ...prev, businessType: '' }));
        setCustomBusinessType('');
        setTimeout(() => customInputRef.current?.focus(), 0);
      } else {
        setIsCustom(false);
        setFormData(prev => ({ ...prev, businessType: value }));
        setCustomBusinessType('');
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleCustomBusinessTypeChange = (e) => {
    const value = e.target.value;
    setCustomBusinessType(value);
    setFormData(prev => ({ ...prev, businessType: value }));
  };

  const validateForm = () => {
    if (!formData.businessType) {
      setError('Please select or enter a business type');
      return false;
    }
    if (!place) {
      setError('Please select a location');
      return false;
    }
    if (radius <= 0 || radius > 50) {
      setError('Please enter a valid radius between 0 and 50 km');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!auth) {
      setShowLoginPrompt(true);
      return;
    }

    if (userCredit <= 0) {
      setShowCreditPopup(true);
      return;
    }

    setIsLoading(true);
    try {
      const data = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        business_type: formData.businessType,
        radius: radius,
        without_website: formData.withoutWebsite,
        operational: formData.operational,
        has_phone: formData.hasPhone,
        has_recent_reviews: formData.hasRecentReviews,
        has_reviews: formData.hasReviews
      };

      const response = await getBusinessLeads(data, auth.token);

      // Check if the response is the "no results" object
      if (typeof response === 'object' && response.status === 'success' && response.message === 'No results found') {
        setError('We apologize, but no business leads were found matching your criteria. Please try adjusting your search parameters or expanding your search area.');
        return;
      }

      // Check if the response is a non-empty string (CSV data)
      if (typeof response === 'string' && response.trim().length > 0) {
        const newCredit = await fetchUserCredit();
        onCreditUpdate(newCredit);
        downloadCSV(response);
      } else {
        setError('We apologize, but no business leads were found matching your criteria. Please try adjusting your search parameters or expanding your search area.');
      }
    } catch (error) {
      if (error?.response?.data?.status === 'error' && error?.response?.data?.message === 'No result found') {
        setError('We apologize, but no business leads were found matching your criteria. Please try adjusting your search parameters or expanding your search area.');
      } else {
        setError('An unexpected error occurred while fetching data. Please try again or contact support if the issue persists.');
      }
      console.error('Error fetching business leads:', error?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'business_leads.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <motion.form
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-lg"
      >
        <h2 className="text-2xl font-semibold mb-4 text-purple-800">Get Business Leads</h2>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-purple-700">Business Type</label>
          <select
            name="businessType"
            value={isCustom ? 'other' : formData.businessType}
            onChange={handleInputChange}
            className="w-full p-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-200 bg-white"
          >
            <option value="">Select a business type</option>
            {Array.isArray(businessTypes) && businessTypes.map((type) => (
              <option key={type.id} value={type.value}>
                {type.name}
              </option>
            ))}
            <option value="other">Other</option>
          </select>
          <AnimatePresence>
            {isCustom && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={customInputRef}
                  type="text"
                  value={customBusinessType}
                  onChange={handleCustomBusinessTypeChange}
                  placeholder="Enter custom business type"
                  className="mt-2 w-full p-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-200"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="space-y-3">
          {[
            { name: 'withoutWebsite', label: 'Without Website' },
            { name: 'operational', label: 'Operational' },
            { name: 'hasPhone', label: 'Has Phone Number' },
            { name: 'hasRecentReviews', label: 'Has Recent Reviews' },
            { name: 'hasReviews', label: 'Has Reviews' }
          ].map((field) => (
            <div key={field.name} className="flex items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id={field.name}
                  name={field.name}
                  checked={formData[field.name]}
                  onChange={handleInputChange}
                  className="form-checkbox h-5 w-5 text-purple-600 transition duration-150 ease-in-out cursor-pointer"
                />
                <span className="ml-2 text-sm text-gray-700">{field.label}</span>
              </label>
            </div>
          ))}
        </div>

        {error && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-red-500 mt-4"
          >
            {error}
          </motion.p>
        )}

        <motion.button
          type="submit"
          className="mt-6 bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-6 py-3 rounded-lg font-semibold text-lg shadow-md hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-200"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isLoading}
        >
          {isLoading ? 'Fetching Data...' : 'Get Business Leads'}
        </motion.button>
      </motion.form>

      <AnimatePresence>
        {showLoginPrompt && (
          <LoginPrompt onClose={() => setShowLoginPrompt(false)} />
        )}
        {showCreditPopup && (
          <CreditPopup onClose={() => setShowCreditPopup(false)} />
        )}
      </AnimatePresence>
    </>
  );
}

export default BusinessLeadsForm;