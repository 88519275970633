import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { fetchPricingPlans } from '../utils/api';
import LoginPrompt from '../components/LoginPrompt';

const PricingPage = () => {
    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);
    const [processingPlanId, setProcessingPlanId] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const { auth } = useAuth();

    useEffect(() => {
        const loadPlans = async () => {
            try {
                const data = await fetchPricingPlans();
                setPlans(data);
                setIsLoading(false);
            } catch (err) {
                setError('Failed to load pricing plans. Please try again later.');
                setIsLoading(false);
            }
        };
        loadPlans();
    }, []);

    const handleGetStarted = async (planId) => {
        if (!auth) {
            setShowLoginPrompt(true);
            return;
        }

        setProcessingPlanId(planId);
        setRedirectUrl(true); // Show redirecting popup immediately

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/payment/make-payment/`,
                { plan_id: planId },
                {
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                // Redirect after a short delay to allow for animation
                window.location.href = response.data.approval_url;
                setRedirectUrl(false);

            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (err) {
            setRedirectUrl(false);
            setShowErrorPopup(true);
        } finally {
            setProcessingPlanId(null);
        }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3
            }
        }
    };

    const planVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100
            }
        }
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    const popupVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 20
            }
        },
        exit: {
            scale: 0.8,
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <motion.div
                    className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <motion.p
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-red-500 text-xl"
                >
                    {error}
                </motion.p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <motion.div
                    className="text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Choose the Right Plan for You
                    </h2>
                    <p className="mt-4 text-xl text-gray-600">
                        Get the credits you need to grow your business
                    </p>
                </motion.div>
                <motion.div
                    className="mt-16 grid gap-8 lg:grid-cols-3 lg:gap-x-8"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    {plans.map((plan, index) => (
                        <motion.div
                            key={plan.name}
                            className="relative bg-white rounded-2xl shadow-xl overflow-hidden"
                            variants={planVariants}
                        >
                            <div className="p-8">
                                <h3 className="text-2xl font-semibold text-gray-900">
                                    {plan.name}
                                </h3>
                                <p className="mt-4 flex items-baseline text-gray-900">
                                    <span className="text-5xl font-extrabold tracking-tight">
                                        ${plan.price}
                                    </span>
                                </p>
                                <p className="mt-6 text-gray-500">
                                    Get {plan.credits} credits
                                </p>
                                <ul className="mt-6 space-y-4">
                                    <li className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p className="ml-3 text-base text-gray-700">
                                            CSV download
                                        </p>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p className="ml-3 text-base text-gray-700">
                                            Access to all features
                                        </p>
                                    </li>
                                    <li className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <p className="ml-3 text-base text-gray-700">
                                            24/7 customer support
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="px-6 py-8 bg-gray-50 space-y-6 sm:px-10 sm:py-10">
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    type="button"
                                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => handleGetStarted(index + 1)}
                                    disabled={processingPlanId !== null}
                                >
                                    {processingPlanId === index + 1 ? 'Processing...' : 'Get started'}
                                </motion.button>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>

            <AnimatePresence>
                {showLoginPrompt && (
                    <LoginPrompt onClose={() => setShowLoginPrompt(false)} isPricingPage={true} />
                )}
            </AnimatePresence>

            <AnimatePresence>
                {redirectUrl && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        variants={overlayVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            variants={popupVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className="bg-white p-8 rounded-lg shadow-xl"
                        >
                            <p className="text-xl font-semibold mb-4">Redirecting to payment page...</p>
                            <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin mx-auto"></div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {showErrorPopup && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        variants={overlayVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            variants={popupVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className="bg-white p-8 rounded-lg shadow-xl max-w-md"
                        >
                            <h3 className="text-xl font-semibold mb-4 text-red-600">Oops! Something went wrong</h3>
                            <p className="text-gray-700 mb-6">
                                We encountered an unexpected error while processing your request. This could be due to a temporary issue on our end. Please try again in a few moments, or contact our support team if the problem persists.
                            </p>
                            <button
                                onClick={() => setShowErrorPopup(false)}
                                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                            >
                                Close
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PricingPage;