import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Home from './pages/Home';
import PricingPage from './pages/Pricing';
import UnderDevelopment from './components/UnderDevelopment';

function AppRouter() {
    const navbarRef = useRef();

    const handleCreditUpdate = (newCredit) => {
        if (navbarRef.current && navbarRef.current.updateCredit) {
            navbarRef.current.updateCredit(newCredit);
        }
    };

    return (
        <Router>
            <Navbar ref={navbarRef} />
            <Routes>
                <Route path="/" element={<Home onCreditUpdate={handleCreditUpdate} />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/about" element={<UnderDevelopment />} />
                <Route path="/profile" element={<UnderDevelopment />} />
                {/* Add more routes here */}
            </Routes>
        </Router>
    );
}

export default AppRouter;