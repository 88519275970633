// src/components/Home/LoginPrompt.js
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const LoginPrompt = ({ onClose, isPricingPage }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        exit={{ scale: 0.9, y: 20 }}
        className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full m-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold mb-4 text-purple-800">Login Required</h2>
        {isPricingPage ? <p className="mb-6 text-gray-600">
          Please log in to Get started with Leadsmint.
        </p>
          : <p className="mb-6 text-gray-600">
            Please log in to generate the CSV file with business leads.
          </p>}
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            Cancel
          </button>
          <Link
            to="/login"
            className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors"
          >
            Log In
          </Link>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default LoginPrompt;