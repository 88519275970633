import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Outlet } from 'react-router-dom';
import Controls from '../components/Home/Controls';
import Map from '../components/Home/Map';
import BusinessLeadsForm from '../components/Home/BusinessLeadsForm';

function Home({ onCreditUpdate }) {
  const [mapCenter, setMapCenter] = useState({ lat: 27.4975, lng: -82.5707 });
  const [radius, setRadius] = useState(0);
  const [place, setPlace] = useState(null);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-7xl mx-auto p-8"
      >
        <h1 className="text-4xl font-bold text-center mb-8 text-purple-800">Business Lead Finder</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <Controls setMapCenter={setMapCenter} setRadius={setRadius} setPlace={setPlace} />
            <Map center={mapCenter} radius={radius} place={place} />
          </div>
          <BusinessLeadsForm place={place} radius={radius} onCreditUpdate={onCreditUpdate} />
        </div>
      </motion.div>
      <Outlet />
    </div>
  );
}

export default Home;