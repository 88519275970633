import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

function Controls({ setMapCenter, setRadius, setPlace }) {
  const [location, setLocation] = useState('');
  const [radiusValue, setRadiusValue] = useState(10);
  const [error, setError] = useState('');
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        document.getElementById('location-input')
      );
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    }
  }, []);

  const handlePlaceSelect = () => {
    setError(''); // Clear any previous errors
    const selectedPlace = autocompleteRef.current.getPlace();

    if (!selectedPlace) {
      setError('Failed to select a place. Please try again.');
      return;
    }

    if (!selectedPlace.geometry || !selectedPlace.geometry.location) {
      setError('Selected place does not have valid geometry. Please choose a different location.');
      return;
    }

    try {
      setMapCenter({
        lat: selectedPlace.geometry.location.lat(),
        lng: selectedPlace.geometry.location.lng(),
      });
      setPlace(selectedPlace);
      setLocation(selectedPlace.formatted_address || '');
    } catch (err) {
      console.error('Error setting place:', err);
      setError('An error occurred while setting the location. Please try again.');
    }
  };

  useEffect(() => {
    setRadius(radiusValue);
  }, [radiusValue, setRadius]);

  return (
    <motion.div 
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col gap-4 mb-6"
    >
      <div>
        <input
          id="location-input"
          type="text"
          placeholder="Enter a location"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
            setError(''); // Clear error when user types
          }}
          className="w-full p-2 rounded-lg border border-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
        />
        {error && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-red-500 mt-2 text-sm"
          >
            {error}
          </motion.p>
        )}
      </div>
      {/* <div className="flex items-center gap-4">
        <input
          type="range"
          min="0"
          max="50"
          value={radiusValue}
          onChange={(e) => setRadiusValue(Number(e.target.value))}
          className="w-full"
        />
        <span className="text-purple-800 font-semibold">{radiusValue} km</span>
      </div> */}
    </motion.div>
  );
}

export default Controls;

