import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


import { LoadScript } from '@react-google-maps/api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={['places']}
    >
      <App />
    </LoadScript>
  </React.StrictMode>
);