// components/Map.js
import React, { useRef, useEffect } from 'react';
import { GoogleMap, Circle, Marker } from '@react-google-maps/api';
import { motion } from 'framer-motion';

const containerStyle = {
  width: '100%',
  height: '400px'
};

function Map({ center, radius, place }) {
  const mapRef = useRef(null);
  const circleRef = useRef(null);

  const onLoad = React.useCallback(function callback(map) {
    mapRef.current = map;
    if (place && place.geometry) {
      const bounds = new window.google.maps.LatLngBounds(place.geometry.location);
      map.fitBounds(bounds);
    }
  }, [place]);

  useEffect(() => {
    if (mapRef.current && place && place.geometry) {
      mapRef.current.panTo(place.geometry.location);
    }
  }, [place]);

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.setRadius(radius * 1000);
    }
  }, [radius]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="rounded-lg overflow-hidden shadow-lg"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
      >
        {place && place.geometry && place.geometry.location && (
          <>
            <Marker position={place.geometry.location} />
            <Circle
              center={place.geometry.location}
              radius={radius * 1000}
              options={{
                fillColor: '#8B5CF6',
                fillOpacity: 0.2,
                strokeColor: '#8B5CF6',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
              onLoad={(circle) => {
                circleRef.current = circle;
              }}
            />
          </>
        )}
      </GoogleMap>
    </motion.div>
  );
}

export default React.memo(Map);