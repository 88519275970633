import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getUserCredit } from '../../utils/api';

const Navbar = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [credit, setCredit] = useState(null);
  const [showCreditPopup, setShowCreditPopup] = useState(false);
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const creditControls = useAnimation();

  useEffect(() => {
    if (auth) {
      fetchUserCredit();
    }
  }, [auth]);

  const fetchUserCredit = async () => {
    try {
      const creditData = await getUserCredit(auth.token);
      setCredit(creditData?.balance);
    } catch (error) {
      console.error('Failed to fetch user credit:', error);
    }
  };

  useImperativeHandle(ref, () => ({
    updateCredit: async (newCredit) => {
      await creditControls.start({ scale: 1.2, transition: { duration: 0.2 } });
      setCredit(newCredit);
      await creditControls.start({ scale: 1, transition: { duration: 0.2 } });
    }
  }));

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleAddCredits = () => {
    setShowCreditPopup(false)
    navigate('/pricing');
  };

  const sidebarVariants = {
    open: { x: 0 },
    closed: { x: '-100%' },
  };

  const popupVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.nav
      className="bg-gradient-to-r from-purple-600 to-indigo-600 p-4 shadow-lg"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <Link to="/" className="text-white text-2xl font-bold">
          <motion.span
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            Leadsmint
          </motion.span>
        </Link>

        {/* Desktop menu */}
        <div className="hidden md:flex space-x-4 items-center">
          {auth && (
            <div className="flex items-center space-x-2">
              <motion.div
                className="bg-white font-semibold text-purple-600 px-4 py-2 rounded-full"
                initial={{ opacity: 1 }}
                animate={creditControls}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                Credits: {credit !== null ? credit : 'Loading...'}
              </motion.div>
              <motion.button
                onClick={handleAddCredits}
                className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:bg-indigo-700 text-white px-3 py-2 rounded-full text-sm"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Buy now
              </motion.button>
            </div>
          )}
          <NavLink to="/about">About</NavLink>
          <NavLink to="/pricing">Pricing</NavLink>
          <NavLink to="/profile">Profile</NavLink>
          {auth ? (
            <motion.button
              onClick={handleLogout}
              className="text-white hover:text-purple-200 transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Logout
            </motion.button>
          ) : (
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="/login" className="bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition-colors">
                Login
              </Link>
            </motion.div>
          )}
        </div>

        {/* Mobile menu button */}
        <motion.button
          onClick={toggleSidebar}
          className="md:hidden text-white"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </motion.button>
      </div>

      {/* Sidebar for mobile */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
            transition={{ duration: 0.3, type: "spring", stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 w-64 h-full bg-purple-800 z-50 p-4 md:hidden"
          >
            <motion.button
              onClick={toggleSidebar}
              className="text-white mb-4"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </motion.button>
            <div className="flex flex-col space-y-4">
              <NavLink to="/about" mobile>About</NavLink>
              <NavLink to="/pricing" mobile>Pricing</NavLink>
              <NavLink to="/profile" mobile>Profile</NavLink>
              {auth && (
                <div className="flex flex-col space-y-2">
                  <motion.div
                    className="bg-white text-purple-600 px-4 py-2 rounded-full text-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Credits: {credit !== null ? credit : 'Loading...'}
                  </motion.div>
                  <motion.button
                    onClick={handleAddCredits}
                    className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-4 py-2 rounded-full hover:bg-indigo-700 transition-colors text-center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Buy now
                  </motion.button>
                </div>
              )}
              {auth ? (
                <motion.button
                  onClick={handleLogout}
                  className="bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition-colors text-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Logout
                </motion.button>
              ) : (
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link to="/login" className="bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition-colors text-center block">
                    Login
                  </Link>
                </motion.div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
});

// NavLink component for consistent styling and animations
function NavLink({ to, children, mobile }) {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link
        to={to}
        className={`${mobile ? 'text-white' : 'text-white hover:text-purple-200'} transition-colors`}
      >
        {children}
      </Link>
    </motion.div>
  );
}

export default Navbar;